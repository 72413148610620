/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
}

html, body {
  height: 100%;
}

//pdf组件样式
.ng2-pdf-viewer-container {
  background-color: #eee;
  padding-bottom: 17rem;
}
